import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "notif-center-table" }
const _hoisted_2 = {
  key: 0,
  class: "table-grid__cell"
}
const _hoisted_3 = {
  key: 0,
  class: "table-grid__cell"
}
const _hoisted_4 = {
  key: 1,
  class: "table-grid__cell"
}
const _hoisted_5 = {
  key: 2,
  class: "table-grid__cell"
}
const _hoisted_6 = {
  key: 3,
  class: "table-grid__cell"
}
const _hoisted_7 = {
  key: 4,
  class: "table-grid__cell"
}
const _hoisted_8 = {
  key: 5,
  class: "table-grid__cell"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_HeadCellDefault = _resolveComponent("HeadCellDefault")
  const _component_CellDate = _resolveComponent("CellDate")
  const _component_NtCellUser = _resolveComponent("NtCellUser")
  const _component_NtCellMessage = _resolveComponent("NtCellMessage")
  const _component_NtCellAction = _resolveComponent("NtCellAction")
  const _component_TableGrid = _resolveComponent("TableGrid")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableGrid, {
      columns: _ctx.columns,
      rows: _ctx.tableData,
      onChangeSelectedRows: _ctx.changeSelectedRows,
      onDblClickRow: _ctx.dblClickRow
    }, {
      th: _withCtx(({ colData }) => [
        (colData.key=='massAction')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2))
          : (_openBlock(), _createBlock(_component_HeadCellDefault, {
              key: 1,
              modelValue: colData.order,
              "onUpdate:modelValue": $event => ((colData.order) = $event),
              label: colData.label,
              sort: colData.sort,
              btnSortType: "type-2",
              onChange: $event => (_ctx.sortTable(colData.order, colData.key))
            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "sort", "onChange"]))
      ]),
      td: _withCtx(({ colData, rowData }) => [
        (colData.key=='key1')
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "key1"))
          : (colData.key=='createAt')
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_CellDate, {
                  showTime: true,
                  data: { row: rowData },
                  groupKey: colData.keyGroup,
                  fieldKey: colData.keyProp
                }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
              ]))
            : (colData.key=='user')
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_NtCellUser, {
                    data: { row: rowData },
                    groupKey: colData.keyGroup,
                    fieldKey: colData.keyProp
                  }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                ]))
              : (colData.key=='message')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_NtCellMessage, {
                      data: { row: rowData },
                      groupKey: colData.keyGroup,
                      fieldKey: colData.keyProp
                    }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                  ]))
                : (colData.key=='action')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(_component_NtCellAction, {
                        data: { row: rowData },
                        groupKey: colData.keyGroup,
                        fieldKey: colData.keyProp
                      }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                    ]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(rowData?.[colData.keyProp] || '–'), 1 /* TEXT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["columns", "rows", "onChangeSelectedRows", "onDblClickRow"])
  ]))
}