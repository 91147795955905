import { defineStore } from 'pinia';
import dayjs from 'dayjs';

import helper from '@/app/utils/helper';

export const useNotifStore = defineStore('notifStore', {
	state() {
		return {
			isViewLoader: false,

			notifData: [
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: new Date(),
					senderType: 'user',
					notifType: 'agreement',
					isRead: false,
					message: 'Все поверхности работают. <br> Можно проверить в личном кабинете.',
					user: {
						avatar: '/assets/img/res/bs-avatar/demo-avatar.jpg',
						name: 'Терешков Павел',
						firstName: 'Павел',
						lastName: 'Терешков',
						contacts: {
							email: 'test@test.ru',
							phone: '8(999)999-99-99',
							telegram: '@testTest',
						},
					}
				},
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: dayjs().add(-1, 'months').toDate(),
					senderType: 'user',
					notifType: 'tp-read',
					isRead: true,
					message: '10 РС/РП на согласование в АП [45] «Пятерочка»',
					user: {
						avatar: '',
						name: 'Данилов Евгений',
						firstName: 'Евгений',
						lastName: 'Данилов',
						contacts: {
							email: 'test@test.ru',
							phone: '8(999)888-88-88',
							telegram: '@testTest',
						},
					}
				},
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: dayjs().add(-2, 'months').toDate(),
					senderType: 'system',
					notifType: 'tp-read',
					isRead: true,
					message: '5 РС/РП в АП [52] «Альфа-Банк» в апреле 2024 перешли в статус «Свобода».',
				},
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: dayjs().add(-2, 'months').toDate(),
					senderType: 'system',
					notifType: 'tp-read',
					isRead: false,
					message: 'Тестовое сообщение. Тестовое сообщение. Тестовое сообщение. Тестовое сообщение. ',
				},
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: dayjs().add(-3, 'months').toDate(),
					senderType: 'user',
					notifType: 'agreement',
					isRead: false,
					message: '10 РС/РП на согласование в АП [45] «Пятерочка»',
					user: {
						avatar: '/assets/img/res/bs-avatar/demo-avatar.jpg',
						name: 'Данилов Евгений',
						firstName: 'Евгений',
						lastName: 'Данилов',
						contacts: {
							email: 'test@test.ru',
							phone: '8(999)888-88-88',
							telegram: '@testTest',
						},
					}
				},
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: dayjs().add(-3, 'months').toDate(),
					senderType: 'user',
					notifType: 'agreement',
					isRead: false,
					message: '10 РС/РП на согласование в АП [45] «Пятерочка»',
					user: {
						avatar: '',
						name: 'Данилов Евгений',
						firstName: 'Евгений',
						lastName: 'Данилов',
						contacts: {
							email: 'test@test.ru',
							phone: '8(999)888-88-88',
							telegram: '@testTest',
						},
					}
				},
			],

			tableData: [
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: new Date(),
					senderType: 'user',
					notifType: 'agreement',
					isRead: false,
					message: 'Все поверхности работают. <br> Можно проверить в личном кабинете.',
					user: {
						avatar: '/assets/img/res/bs-avatar/demo-avatar.jpg',
						name: 'Терешков Павел',
						firstName: 'Павел',
						lastName: 'Терешков',
						contacts: {
							email: 'test@test.ru',
							phone: '8(999)999-99-99',
							telegram: '@testTest',
						},
					}
				},
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: dayjs().add(-1, 'months').toDate(),
					senderType: 'user',
					notifType: 'tp-read',
					isRead: true,
					message: '10 РС/РП на согласование в АП [45] «Пятерочка»',
					user: {
						avatar: '',
						name: 'Данилов Евгений',
						firstName: 'Евгений',
						lastName: 'Данилов',
						contacts: {
							email: 'test@test.ru',
							phone: '8(999)888-88-88',
							telegram: '@testTest',
						},
					}
				},
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: dayjs().add(-2, 'months').toDate(),
					senderType: 'system',
					notifType: 'tp-read',
					isRead: true,
					message: '5 РС/РП в АП [52] «Альфа-Банк» в апреле 2024 перешли в статус «Свобода».',
				},
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: dayjs().add(-2, 'months').toDate(),
					senderType: 'system',
					notifType: 'tp-read',
					isRead: false,
					message: 'Тестовое сообщение. Тестовое сообщение. Тестовое сообщение. Тестовое сообщение. ',
				},
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: dayjs().add(-2, 'months').toDate(),
					senderType: 'system',
					notifType: 'agreement-success',
					agreementDate: new Date(),
					isRead: true,
					message: '5 РС/РП в АП [52] «Альфа-Банк» в апреле 2024 перешли в статус «Свобода».',
				},
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: dayjs().add(-2.5, 'months').toDate(),
					senderType: 'system',
					notifType: 'agreement-not',
					agreementDate: new Date(),
					isRead: true,
					message: '5 РС/РП в АП [52] «Альфа-Банк» в апреле 2024 перешли в статус «Свобода».',
				},
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: dayjs().add(-3, 'months').toDate(),
					senderType: 'user',
					notifType: 'agreement',
					isRead: false,
					message: '10 РС/РП на согласование в АП [45] «Пятерочка»',
					user: {
						avatar: '/assets/img/res/bs-avatar/demo-avatar.jpg',
						name: 'Данилов Евгений',
						firstName: 'Евгений',
						lastName: 'Данилов',
						contacts: {
							email: 'test@test.ru',
							phone: '8(999)888-88-88',
							telegram: '@testTest',
						},
					}
				},
				{
					id: helper.hash,
					targetProgramId: 3799,
					createAt: dayjs().add(-3, 'months').toDate(),
					senderType: 'user',
					notifType: 'agreement',
					isRead: false,
					message: '10 РС/РП на согласование в АП [45] «Пятерочка»',
					user: {
						avatar: '',
						name: 'Данилов Евгений',
						firstName: 'Евгений',
						lastName: 'Данилов',
						contacts: {
							email: 'test@test.ru',
							phone: '8(999)888-88-88',
							telegram: '@testTest',
						},
					}
				},
			],
		}
	},

	getters: { },
	actions: {

	},
});